import { Divider } from "@material-ui/core";
import { React, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  useMediaQuery,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import axios from 'axios';
import { useFormik } from 'formik';
import Button from "@mui/material/Button";
import { Typography } from "@material-ui/core";
import useStyles from "../theme/style.js";
import CloseIcon from "@material-ui/icons/Close";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ActionsTwo() {
  const classes = useStyles();
  const isDesktop = useMediaQuery("(min-width:768px)");

  const [contactOpen, setContactOpen] = useState(false);
  const [demoOpen, setDemoOpen] = useState(false);

  const handleClose = () => {
    setContactOpen(false);
  };

  // const handleContactClick = () => {
  //   setContactOpen(true);
  // };

  const handleDemoClick = () => {
    setDemoOpen(true)
  }



  const contactFormSchema = Yup.object().shape({
    companyName: Yup.string().max(255).required("Name of the your company is required"),
    mobile: Yup.string()
      .required("Mobile Number is required")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
    email: Yup.string()
      .required("Email ID is required")
      .email("Email must be a Valid Email Address"),
    countries: Yup.string(),
    message: Yup.string()
  });

  const demoFormSchema = Yup.object().shape({
    companyName: Yup.string().max(255).required("Name of the your Company is required"),
    mobile: Yup.string()
      .required("Mobile Number is required")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
    email: Yup.string()
      .required("Email ID is required")
      .email("Email must be a Valid Email Address"),
    countries: Yup.string(),
    message: Yup.string()
  });

  const contactFormik = useFormik({
    initialValues: {
      companyName: '',
      email: '',
      message: '',
      mobile: '',
    },
    validationSchema: contactFormSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      axios
        .post('http://192.168.29.75:4000/api/auth/contactSales', values)
        .then((response) => {
          toast.success('Form submitted successfully');
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setSubmitting(false);
          resetForm();
        });
    },
  });

  const demoFormik = useFormik({
    initialValues: {
      companyName: '',
      email: '',
      message: '',
      mobile: '',
    },
    validationSchema: demoFormSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      axios
        .post('http://192.168.29.75:4000/api/auth/contactDemo', values)
        .then((response) => {
          toast.success('Form submitted successfully');
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setSubmitting(false);
          resetForm();
        });
    },
  });


  return (
    <div>
      <Grid container p={5} spacing={2}>
        <Grid item xs={12} md={5}>
          <div className={classes.flexColumnCenter}>
            <Typography
              variant='h3'
              className={classes.actionBtnTitle}
            >
              See <span className='refo-font'>refo</span> in action.
            </Typography>
            <Button
              variant='contained'
              size='large'
              className={classes.primaryBtn}
              onClick={handleDemoClick}
            >
              Fix a Demo
            </Button>
          </div>
        </Grid>
        {isDesktop ? (
          <Grid item xs={12} md={2} className={classes.flexCenter}>
            <Divider orientation='vertical' />
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12} md={5}>
          <div className={classes.flexColumnCenter}>
            <Typography
              variant='h3'
              className={classes.actionBtnTitle}
            >
              Contact refo Onboarding Manager
            </Typography>

            <a href="tel:9025992817" className="contact_call_button">
              
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="#fff" height="800px" class="icon" width="800px" version="1.1" id="Capa_1" viewBox="0 0 473.806 473.806" xmlSpace="preserve">
                <g>
                  <g>
                    <path d="M374.456,293.506c-9.7-10.1-21.4-15.5-33.8-15.5c-12.3,0-24.1,5.3-34.2,15.4l-31.6,31.5c-2.6-1.4-5.2-2.7-7.7-4    c-3.6-1.8-7-3.5-9.9-5.3c-29.6-18.8-56.5-43.3-82.3-75c-12.5-15.8-20.9-29.1-27-42.6c8.2-7.5,15.8-15.3,23.2-22.8    c2.8-2.8,5.6-5.7,8.4-8.5c21-21,21-48.2,0-69.2l-27.3-27.3c-3.1-3.1-6.3-6.3-9.3-9.5c-6-6.2-12.3-12.6-18.8-18.6    c-9.7-9.6-21.3-14.7-33.5-14.7s-24,5.1-34,14.7c-0.1,0.1-0.1,0.1-0.2,0.2l-34,34.3c-12.8,12.8-20.1,28.4-21.7,46.5    c-2.4,29.2,6.2,56.4,12.8,74.2c16.2,43.7,40.4,84.2,76.5,127.6c43.8,52.3,96.5,93.6,156.7,122.7c23,10.9,53.7,23.8,88,26    c2.1,0.1,4.3,0.2,6.3,0.2c23.1,0,42.5-8.3,57.7-24.8c0.1-0.2,0.3-0.3,0.4-0.5c5.2-6.3,11.2-12,17.5-18.1c4.3-4.1,8.7-8.4,13-12.9    c9.9-10.3,15.1-22.3,15.1-34.6c0-12.4-5.3-24.3-15.4-34.3L374.456,293.506z M410.256,398.806    C410.156,398.806,410.156,398.906,410.256,398.806c-3.9,4.2-7.9,8-12.2,12.2c-6.5,6.2-13.1,12.7-19.3,20    c-10.1,10.8-22,15.9-37.6,15.9c-1.5,0-3.1,0-4.6-0.1c-29.7-1.9-57.3-13.5-78-23.4c-56.6-27.4-106.3-66.3-147.6-115.6    c-34.1-41.1-56.9-79.1-72-119.9c-9.3-24.9-12.7-44.3-11.2-62.6c1-11.7,5.5-21.4,13.8-29.7l34.1-34.1c4.9-4.6,10.1-7.1,15.2-7.1    c6.3,0,11.4,3.8,14.6,7c0.1,0.1,0.2,0.2,0.3,0.3c6.1,5.7,11.9,11.6,18,17.9c3.1,3.2,6.3,6.4,9.5,9.7l27.3,27.3    c10.6,10.6,10.6,20.4,0,31c-2.9,2.9-5.7,5.8-8.6,8.6c-8.4,8.6-16.4,16.6-25.1,24.4c-0.2,0.2-0.4,0.3-0.5,0.5    c-8.6,8.6-7,17-5.2,22.7c0.1,0.3,0.2,0.6,0.3,0.9c7.1,17.2,17.1,33.4,32.3,52.7l0.1,0.1c27.6,34,56.7,60.5,88.8,80.8    c4.1,2.6,8.3,4.7,12.3,6.7c3.6,1.8,7,3.5,9.9,5.3c0.4,0.2,0.8,0.5,1.2,0.7c3.4,1.7,6.6,2.5,9.9,2.5c8.3,0,13.5-5.2,15.2-6.9    l34.2-34.2c3.4-3.4,8.8-7.5,15.1-7.5c6.2,0,11.3,3.9,14.4,7.3c0.1,0.1,0.1,0.1,0.2,0.2l55.1,55.1    C420.456,377.706,420.456,388.206,410.256,398.806z" />
                    <path d="M256.056,112.706c26.2,4.4,50,16.8,69,35.8s31.3,42.8,35.8,69c1.1,6.6,6.8,11.2,13.3,11.2c0.8,0,1.5-0.1,2.3-0.2    c7.4-1.2,12.3-8.2,11.1-15.6c-5.4-31.7-20.4-60.6-43.3-83.5s-51.8-37.9-83.5-43.3c-7.4-1.2-14.3,3.7-15.6,11    S248.656,111.506,256.056,112.706z" />
                    <path d="M473.256,209.006c-8.9-52.2-33.5-99.7-71.3-137.5s-85.3-62.4-137.5-71.3c-7.3-1.3-14.2,3.7-15.5,11    c-1.2,7.4,3.7,14.3,11.1,15.6c46.6,7.9,89.1,30,122.9,63.7c33.8,33.8,55.8,76.3,63.7,122.9c1.1,6.6,6.8,11.2,13.3,11.2    c0.8,0,1.5-0.1,2.3-0.2C469.556,223.306,474.556,216.306,473.256,209.006z" />
                  </g>
                </g>
              </svg>
              9025992817
              {/* <svg fill="currentColor" viewBox="0 0 24 24" class="icon">
                <path
                  clip-rule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                  fill-rule="evenodd"
                ></path>
              </svg> */}
            </a>

            {/* <a href="tel:9025992817"
              className={classes.primaryBtn}
              // onClick={handleContactClick}
            >
              9025992817
            </a> */}
          </div>
        </Grid>
      </Grid>

      <Dialog
        onClose={handleClose}
        open={contactOpen}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle
          className={classes.dialogTitlePosition}
        >
          <Typography className={classes.dialogTitle}>Contact Sales</Typography>
          <CloseIcon
            size='14px'
            className={classes.closeBtn}
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent >
          <form onSubmit={contactFormik.handleSubmit} className={classes.formContainer}>
            <TextField
              fullWidth
              id="companyName"
              name="companyName"
              label="Company Name"
              value={contactFormik.values.companyName}
              onChange={contactFormik.handleChange}

              error={contactFormik.touched.companyName && Boolean(contactFormik.errors.companyName)}
              helperText={contactFormik.touched.companyName && contactFormik.errors.companyName}
            />
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email ID"
              value={contactFormik.values.email}
              onChange={contactFormik.handleChange}
              error={contactFormik.touched.email && Boolean(contactFormik.errors.email)}
              helperText={contactFormik.touched.email && contactFormik.errors.email}
            />
            <TextField
              fullWidth
              id="mobile"
              name="mobile"
              label="Mobile Number"
              value={contactFormik.values.mobile}
              onChange={contactFormik.handleChange}
              error={contactFormik.touched.mobile && Boolean(contactFormik.errors.mobile)}
              helperText={contactFormik.touched.mobile && contactFormik.errors.mobile}
            />
            <TextField
              fullWidth
              id="message"
              name="message"
              label="Message"
              multiline
              rows={4}
              value={contactFormik.values.message}
              onChange={contactFormik.handleChange}
              error={contactFormik.touched.message && Boolean(contactFormik.errors.message)}
              helperText={contactFormik.touched.message && contactFormik.errors.message}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              disabled={contactFormik.isSubmitting}
              className={classes.formSubmitBtn}
            >
              {contactFormik.isSubmitting ? 'Submitting...' : 'Submit'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>


      <Dialog
        onClose={() => { setDemoOpen(false) }}
        open={demoOpen}
        className={classes.refoDialog}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle
          className={classes.dialogTitlePosition}
        >
          <Typography className={classes.dialogTitle}>Fix a Demo</Typography>
          <CloseIcon
            size='14px'
            className={classes.closeBtn}
            onClick={() => { setDemoOpen(false) }}
          />
        </DialogTitle>
        <DialogContent >
          <form onSubmit={demoFormik.handleSubmit} className={classes.formContainer}>
            <TextField
              fullWidth
              id="companyName"
              name="companyName"
              label="Company Name"
              value={demoFormik.values.companyName}
              onChange={demoFormik.handleChange}
              error={demoFormik.touched.companyName && Boolean(demoFormik.errors.companyName)}
              helperText={demoFormik.touched.companyName && demoFormik.errors.companyName}
            />
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email ID"
              value={demoFormik.values.email}
              onChange={demoFormik.handleChange}
              error={demoFormik.touched.email && Boolean(demoFormik.errors.email)}
              helperText={demoFormik.touched.email && demoFormik.errors.email}
            />
            <TextField
              fullWidth
              id="mobile"
              name="mobile"
              label="Mobile Number"
              value={demoFormik.values.mobile}
              onChange={demoFormik.handleChange}
              error={demoFormik.touched.mobile && Boolean(demoFormik.errors.mobile)}
              helperText={demoFormik.touched.mobile && demoFormik.errors.mobile}
            />
            <TextField
              fullWidth
              id="message"
              name="message"
              label="Message"
              multiline
              rows={4}
              value={demoFormik.values.message}
              onChange={demoFormik.handleChange}
              error={demoFormik.touched.message && Boolean(demoFormik.errors.message)}
              helperText={demoFormik.touched.message && demoFormik.errors.message}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              disabled={demoFormik.isSubmitting}
              className={classes.formSubmitBtn}
            >
              {demoFormik.isSubmitting ? 'Submitting...' : 'Submit'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
      <ToastContainer />
    </div>
  );

}

export default ActionsTwo;
