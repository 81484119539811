import {  Grid, } from '@mui/material'
import React from 'react'
import SubTitlesLogo from '../../components/SubTitlesLogo'
import abstract1 from "../../assets/logo/abstract1.png"
import abstract2 from "../../assets/logo/abstract2.png"
import abstract3 from "../../assets/logo/abstract3.png"
import SimpleBox from '../../components/SimpleBox';
import ColorSlides from '../../components/ColorSlides'
import ButterFly from "../../assets/designs/butterfly.png"

function Resources() {
  

  return (
    <div>
        
        <ColorSlides shapeImg={ButterFly}  bgcolor='rgb(133 147 255 / 93%)' />
  
      <Grid container spacing={2} justifyContent="center" p={3} mt={1}>
        <Grid item sx={{ xs: 3, md: 12, lg: 4 }}>
          <SimpleBox title="Free credits" content="Steady flow of Requirements taking care of your order book"  selectedColor="#3ecd5e"/>
        </Grid>
        <Grid item sx={{ xs: 3, md: 12, lg: 4 }}>
          <SimpleBox title="Engage" content="Quickly engage-disengage model giving great flexibility" selectedColor="#952aff" />
        </Grid>
        <Grid item sx={{ xs: 3, md: 12, lg: 4 }}>
          <SimpleBox title="Update" content="Seamlessly update each candidate info on chat and get responses from refo support"  selectedColor="#cd3e94" />
        </Grid>
        <Grid item sx={{ xs: 3, md: 12, lg: 4 }}>    
         <SimpleBox title="Workflow" content="Amazing recruit workflow to know candidate current hire status till invoicing & payment" selectedColor="#e44002" />
        </Grid>
      </Grid>
        <Grid container spacing={2} justifyContent='space-evenly' padding="0px 0px 40px 0px">
        <Grid item sx={{ xs: 3, md: 12, lg: 4 }}>
          <SubTitlesLogo
            customIcon={abstract1}
            title='Listening comes naturally to us share your feedback'
          />
        </Grid>
        <Grid item sx={{ xs: 3, md: 12, lg: 4 }}>
          <SubTitlesLogo
            customIcon={abstract2}
            title='Your ideas can make our product better'
          />
        </Grid>
        <Grid item sx={{ xs: 3, md: 12, lg: 4 }}>
          <SubTitlesLogo
            customIcon={abstract3}
            title='Transforming your business is our core objective'
          />
        </Grid>
      </Grid>
      
    </div>
  )
}

export default Resources