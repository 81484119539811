import React from "react";
import { useState } from "react";
import {
  Grid,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Toolbar,AppBar } from "@material-ui/core";
import useStyles from "../theme/style.js";
import Popover from '@mui/material/Popover';
import DrawerComp from "./DrawerComp";
import { Link } from "react-scroll";
import icon1 from "../assets/Icons/home.png"


const Navbar = ({ links }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [activeLink, setActiveLink] = useState('');

  const handleSetActiveLink = (link) => {
    setActiveLink(link);
  };



  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  //using theme and mediaquery for responsive
  const theme = useTheme();
  const classes = useStyles();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <AppBar className={classes.appBarBg}>
        <Toolbar className={classes.toolBar}>
          {isMatch ? (
            <>
              <div className={classes.refoTitleMobile}>
                <p className="refo-name">refo</p>
              </div>
              <DrawerComp links={links}  />
            </>
          ) : (
            <Grid sx={{ placeItems: "center" }} container spacing={2}>
              <Grid item xs={1} md={1} className={classes.refoTitle}>
                  <p className="refo-name">refo</p>
              </Grid>
              <Grid item xs={9} md={7} className={classes.flexCenter}> 

                <nav>
                  <ul>
                    <li className="list">
                    <img src={icon1} alt="Home" style={{width:"20px"}}/>
                      <Link
                          to="home"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={2000}
                          onSetActive={handleSetActiveLink}
                          className={activeLink === 'home'  ? 'active' : ''}
                        >
                          Home
                        </Link>
                    </li>
                    <li className="list">
                    {/* <img src={icon2} alt="Why refo"/> */}
                      <Link
                        to="why_refo"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={2000}
                        onSetActive={handleSetActiveLink}
                        className={activeLink === 'why_refo'  ? 'active' : ''}
                      >
                        Why refo
                      </Link>
                    </li>
                    
                    
                    <li className="list">
                    {/* <img src={icon5} alt="resource"/> */}
                      <Link
                        to="customer_experience"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={2000}
                        onSetActive={handleSetActiveLink}
                        className={activeLink === 'customer_experience'  ? 'active' : ''}
                      >
                        Customer Experience
                      </Link>
                    </li>
                  </ul>
                </nav>
              </Grid>

              <Grid item xs={2} md={4} className={classes.loginContainer}>
               <Box className="registration_firm">
                      <a
                        href={`${process.env.REACT_APP_DOMAIN_URL}v1/#/register`}
                        className="iconBlink"
                      >
                        Recruitment Firm Registration
                      </a>
                </Box>
                <div style={{display:'flex',flexDirection:"column",marginBottom:"30px"}} >
                  <p style={{display:"flex",justifyContent:"flex-end"}}>{" "}</p>
                  <Box>
                  <a href={`${process.env.REACT_APP_DOMAIN_URL}v1/#/login`} className={classes.btnLogin}> Login </a>
                  </Box>
                </div>
              </Grid>
            </Grid>
          )}
        </Toolbar>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
        </Popover>
      </AppBar>
    </>
  );
};

export default Navbar;
