import React from 'react'
import { Typography } from "@material-ui/core";
import { Grid } from "@mui/material";
import useStyles from '../theme/style'

function SubTitlesLogo({title,customIcon}) {
  
  const classes = useStyles()

  return ( 
    <div className={classes.subTitleContainer}>
        <Grid container direction='column' gap="15px" p={2} >
            <Grid item className={classes.subTitleImg}>
                <img src={customIcon} alt='images'/>
            </Grid>
            <Grid item >
                <Typography variant='h6' className={classes.subTitleHead}>{title}</Typography>
            </Grid>
            
            <Grid item  >
            </Grid>
        </Grid>
    </div>
  )
}

export default SubTitlesLogo