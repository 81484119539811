import React from "react";
import useStyles from "../theme/style.js";
import { Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import ActionsTwo from "./ActionsTwo.js";

function DetailList() {
  const classes = useStyles();
  return (
    <div>
      
      <Divider className={classes.dividerColor} width='80%' variant='middle' />
      <Grid
        container
        direction='row'
        spacing={2}
        mt="-1px"
        justifyContent='center'
        className={[classes.headBG,classes.flexCenter]}
        p={5}
      >
      <Grid item xs={12}>
        <ActionsTwo />
      </Grid>
        <Grid item xs={12} md={6} >
          <div className={classes.footerIcon}>
            <TwitterIcon />
            <InstagramIcon />
            <LinkedInIcon />
            <FacebookIcon />
            <YouTubeIcon />
          </div>
        </Grid>
        <Grid item xs={12} md={6} >
          <ul className={classes.footerUl}>
            <li>Subscriptions</li>
            <li>Cookie Preferences</li>
            <li>Privacy</li>
            {/* <li>© 2023 INFOAPTO, Inc.</li> */}
          </ul>
        </Grid>
      </Grid>
    </div>
  );
}

export default DetailList;
