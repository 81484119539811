import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';


export default function CardDetails({imageUrl,title,content}) {
  return (
    <div style={{padding:"10px"}}>
    <Card sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",pointerEvents:"none"}} >
      <CardActionArea>
      
        <CardContent>
          <Typography gutterBottom variant="h6" component="div" style={{textAlign:"center",height:"5rem"}} >
            {title}
          </Typography>
         
          {/* <Typography variant="body2" color="text.secondary">
            {content}
          </Typography> */}
        </CardContent>

        <CardMedia
          component="img"
          height="70"
          image={imageUrl}
          alt="green iguana"
        />
        
      </CardActionArea>
    </Card>
    </div>
  );
}