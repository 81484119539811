import "./index.css";
import Navbar from "./components/Navbar";
import { BrowserRouter, Switch } from "react-router-dom";
import history from "./service/history";
import Home from "./pages/Home";
import {createTheme, MuiThemeProvider } from "@material-ui/core";

function App() {
  const getMuiTheme = () =>
  createTheme({
    overrides: {
      MuiTypography: {
        h1: {
          fontSize: "3rem",
        },
        h2: {
          fontSize: "2rem",
        },
        h3: {
          fontSize: "1.64rem",
        },
        h4: {
          fontSize: "1.5rem",
        },
        h5: {
          color: "black",
          fontSize: "1.285rem",
        },
        h6: {
          fontSize: "1.142rem",
        },
      },
      MuiButtonBase:{
        root:{
          MuiCardActionArea:{
            root:{
               cursor:"none !important"
            }
          } 
        }
      }
    },
    
  });

  const linksArr = [
    { label: "Home", value: "/", },
    { label: "Why refo", value: "#why_refo", },
    { label: "Products", value: "#products", },
   
    {
      label: "Customer Experience",
      value: "#customer_experience",
      
    },
    { label: "Resources", value: "#resources",  },
  ];
  return (
    <div className='App'>
      <MuiThemeProvider theme={getMuiTheme()}>
        <BrowserRouter history={history}>
          <Navbar links={linksArr} />         
          <Home/>
          <Switch>
            {/* <Route path="/why_refo" component={WhyRefo} />
            <Route path="/products" component={Products} />
            <Route path="/industries_solutions" component={CustomerExperience} />
            <Route path="/customer_experience" component={WhyRefo} />
            <Route path="/resources" component={CustomerExperience} /> */}
          </Switch>
        </BrowserRouter>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
