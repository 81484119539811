import { Grid } from '@mui/material'
import React from 'react'
import { Typography } from "@material-ui/core";
import useStyles from "../theme/style.js";
const ColorSlides = ({contentText,bgcolor,shapeImg}) => {
  const classes = useStyles();

  return (
    <div>
        <Grid container p={5} bgcolor={bgcolor}>
            <Grid item xs={12} md={6}  order={{md:1,xs:2}}>
            <div className={classes.colorSlides}>
                <Typography variant='h2' >
                <span className="refo-font">refo</span> is just not a product.
                </Typography>
                <Typography variant='h2' >
                    It solves Recruitment Firms Revenue challenges by way of their quality time and efforts invested only on assured outcomes.
                </Typography>
                <Typography variant='h2' >
                     Its an inclusion of everything you need when it comes to getting people on-board.
                </Typography>
                <Typography variant='h2'  >
                    Reach people for sure.
                </Typography>
            </div>
                
            </Grid>
            <Grid item xs={12} md={6} p={5} className={classes.flexCenter} order={{md:2,xs:1}}>
                <img src={shapeImg} alt='images' width="40%"  className={classes.imgShapes}></img>
            </Grid>
            
        </Grid>
    </div>
  )
}

export default ColorSlides