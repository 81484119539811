import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
    appBarBg:{
        height:"5rem",
        width:"100% !important",
        background:"#243763 !important",
        // backgroundImage: "radial-gradient( circle farthest-corner at 74.4% 21.7%, rgba(4,189,228,1) 0%, rgba(2,83,185,1) 100.2% )",
        boxShadow:"rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
    },
    flexCenter:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
    },
    flexCenterGap:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        gap:"20px",
    },
    flexColumn:{
        display:"flex",
        flexDirection:"column",
        gap:"20px"
    },
    flexColumnCenter:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        gap:"20px"
    },
    footerIcon:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        gap:"20px",
        [theme.breakpoints.only("lg")]:{
            marginRight:"95px"
        },
        [theme.breakpoints.only("md")]:{
            marginRight:"60px"
        }

    },
    endAlign:{
        display:"flex",
        justifyContent:"end",
        alignItems:"center",
    },
    loginContainer:{
        display:"flex",
        justifyContent:"flex-end !important",
        alignItems:"center",
        gap:"20px",
    },
    headImg:{
        height:"80px",
        mixBlendMode:"darken"
    },
    logoImg:{
        width:"10%",
        aspectRatio:"16/9",
        objectFit:"contain"
    },
    headBG:{
        background:"rgb(133 147 255 / 30%)",
        width:"100%",
    },
    indent:{
        textIndent:"60px",
        textAlign:"justify"
    },
    dividerColor: {
        borderBottom: "3px solid transparent ",
        borderImage: "linear-gradient(to right, #ffc629 0%, #ffc629 33%, #ffa126 33%, #ffa126 66%, #ffa126 66%, #ffa126 66%, #0875e1 66%, #0875e1 100%)",
        borderWidth: "medium",
        borderImageSlice:1,
        width: "fit-content",
    },
    commonTitles:{
        fontWeight: "bold",
        color:"#FF6E31",
        "-webkit-text-stroke": "1.5px black",
    },
    btnLogin:{
        width:"80px !important",
        padding:"6px 22px",
        textAlign:'center',
        border:"2px solid #fff",
        borderRadius:"10px",
        textDecoration:"none",
        color:"#fff",
        '&:hover':{
            background:"#fff",
            color:"#243763 ",
        }, 
    },
    headCard:{
        display:"flex",
        flexDirection:"column",
        alignItems:"center"
    },
    headCardTitle:{
        fontWeight: "600",
        color:"rgb(66, 33, 126)"
    },
    whyRefoContainer:{
        display:"flex !important",
        flexDirection:"column !important",
        gap:"20px"
    },
    refoDashboardImg:{
        padding:"40px",
        filter:"drop-shadow(2px 4px 6px #4186ffde)",
        width:"100%",
        [theme.breakpoints.down('md')]:{
            width:"80%"
        },
    },
    refoTitle:{
        padding: "0px !important",
        margin: "0px -8px !important",
        backgroundColor: "#fff",
        height: "88px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    refoTitleMobile:{
        padding: "18px !important",
        margin: "8px 0px 17px -24px !important",
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    toolBar:{
        // position:"fixed",
        // display:"flex !important",
        // justifyContent:"flex-start",
        // alignItems:"center",
        // gap:"30px",
        marginTop:"8px"
    },
    illustration:{
        animation: "illustration 5s ease-in-out infinite alternate-reverse forwards",
        mixBlendMode: "multiply",
        width:"60%",
        [theme.breakpoints.down( 'md')]: {
            width:"80%"
          },
    },
    imgResponsive:{
        width:"100%",
        [theme.breakpoints.down('md')]:{
            width:"80%"
        },
    },
    primaryBtn:{
        background:"#243763 !important",
        borderRadius: "50px !important",
        width: "max-content",
        fontSize: "14px",
        "&:hover":{
            background:"rgb(133 147 255 / 30%) !important",
            color:"#243763 !important",
            border:"1px solid #243763"
        }
    },
    simpleBox:{
        border:"2px solid #a7a7a8b3",
        borderRadius:"5px",
        width:"320px"
    },
    simpleBoxOutline:{
        width:"300px",
        "&:hover": {
            background:"rgb(255, 235, 183,0.7)",
            color: "black",
            cursor:"pointer",
            filter:"dropShadow(16px 16px 10px black)"
          }
    },
    "@keyframes illustration": {
        "0%":{},
        "100%": {
          transform: "translateY(0)"
        },
        "10%":{},
        "30%":{},
        "50%":{},
        "70%" :{
          transform: "translateY(-10px)",
        },
        "20%,":{},
        "40%":{},
        "60%": {
          transform: "translateY(10px)",
        },
        "80%": {
          transform: "translateY(8px)",
        },
        "90%": {
          transform: "translateY(-8px)",
        },
      },
    indicator:{
        maxWidth: 40,
        width: "100%",
        backgroundColor: "white"
    },
    footerUl:{
        listStyle:"none",
        display:"flex",
        flexDirection:"row",
        justifyContent:"center",
        alignItems:"center",
        gap:"20px",
        cursor:"pointer",
        [theme.breakpoints.down('sm')]:{
            flexDirection:"column",
        },
        [theme.breakpoints.only('lg')]:{
            display:"flex",
            justifyContent:"flex-end",
        }
    },
    closeBtn:{
        cursor:"pointer",
        color:"red",
        stroke:"red",
        strokeWidth:"2px"
    },
    cmnTitle:{
        fontWeight:"600",
        color:"#243763"
    },
    imgShapes:{
        filter:"invert(1)",
    },
    colorSlides:{
        display:"flex",
        flexDirection:"column",
        gap:"30px",
        color:"#fff",
        marginTop:"30px"
    },
    subTitleContainer:{
        width:"340px !important"
    },
    subTitleImg:{
        width: "100px",
        mixBlendMode: "darken",
    },
    subTitleHead:{
        fontWeight:"600"
    },
    dialogTitlePosition:{
        display: "flex", 
        justifyContent: "space-between",
        backgroundColor:"#243763"
    },
    dialogTitle:{
        color:"#fff",
    },
    actionBtnTitle:{
        fontWeight: "600 !important",
        color: "rgb(255 49 49)",
    },
    formContainer:{
        display:"flex",
        flexDirection:"column",
        gap:"20px",
        justifyContent:"center",
        alignItems:"center",
        margin:"20px 0px"
    },
    formSubmitBtn:{
        backgroundColor:"#243763 !important",
        color:"#fff !important"
    },
    h6:{
        [theme.breakpoints.down('sm')]:{
            fontSize:"0.825rem",
        }
    },
    h4:{
        [theme.breakpoints.down('sm')]:{
            fontSize:"1.2rem",
        }
    },
    h5:{
        [theme.breakpoints.down('sm')]:{
            fontSize:"1 rem",
        }
    },
    h2:{
        fontSize:"2rem",
        [theme.breakpoints.only('sm')]:{
            fontSize:"1.5rem",
        }
    }
  
}))