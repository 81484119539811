import { Dialog, DialogContent,DialogTitle, Grid } from "@mui/material";
import React from "react";
import { useState } from "react";
import planImg from "../assets/plan.png";
import headGif from "../assets/headBans2.gif";
import { Typography } from "@material-ui/core";
import useStyles from "../theme/style.js";
import Button from "@mui/material/Button";
import CardDetails from "./Card";
import Card1 from "../assets/Card1.png";
import Card2 from "../assets/Card2.png";
import Card3 from "../assets/Card3.png";
import RefoWorkFlow from "../assets/RefoWorkFlow.jpg"
import CloseIcon from "@material-ui/icons/Close";


function Header() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose=()=>{
    setOpen(false)
  }

  const handleClick=()=>{
    setOpen(true)
  }
  return (
    <div>
      <Grid container className={classes.headBG} p={1} sx={{ mt: "5rem" }}>
        <Grid item xs={12} className={classes.flexCenterGap}>
          <img src={headGif} alt='img' className={classes.headImg} />
          <div className={classes.headCard}>
            <Typography variant='h6' className={classes.h6}>
              Introducing <span className="refo-name" style={{fontSize:"1.2rem"}}>refo</span> - Solving Recruitment Firms Active Job Requirements Challenges through steady flow of Top Tier Client Job Requirements
            </Typography>
            <Typography variant='body' className="headerBlink">
              Every <span className="refo-font">Refo</span> User candidate-data is ring fenced by encoding and access is restricted to authorized user-login only!
            </Typography>
          </div>
        </Grid>
      </Grid>

      <Grid container direction='row' alignItems='center' sx={{ mt: "2rem" }}>
        <Grid item xs={12} md={6} p={5} className={classes.whyRefoContainer}>
          <Typography variant="h3"  className={classes.headCardTitle}>
            Get access to pre-vetted pre-qualified job requirements and close them at speed!
          </Typography>
          <Typography variant='h6' className={classes.h6}>
          Seamlessly check cv duplicate status even before sharing in ATS tool login, add cvs' to allocated jobs and manage end-to-end candidate status!
          </Typography>
          <Button
            variant='contained'
            size='medium'
            color="primary"
            sx={{ borderRadius: "50px",width:"max-content", fontSize: "12px",}}
            className={classes.primaryBtn}
            onClick={handleClick}
          >
            Learn more
          </Button>
        </Grid>
        <Grid item xs={12} md={6} pb={5}>
          <img src={planImg} alt='img' className={classes.imgResponsive}/>
        </Grid>
      </Grid>

      <Grid
        container
        direction='row'
        padding='20px'
        className={classes.flexCenterGap}
      >
        <Grid item sm={12} md={4} lg={3}>
          <CardDetails
            imageUrl={Card2}
            title={"Top Tier pre-vetted, pre-qualified job requirements"}
            content={"Enterprise Management Cloud"}
          />
        </Grid>
        <Grid item sm={12} md={4} lg={3}>
          <CardDetails
            imageUrl={Card1}
            title={"Integrated Hi-End ATS(Applicant Tracking System)"}
            content={`${<span className="refo-font">refo</span>}  Adaptive Planning`}
          />
        </Grid>
        <Grid item sm={12} md={4} lg={3}>
          <CardDetails
            imageUrl={Card3}
            title={"Upcoming AI integration for candidate validation & engagement"}
            content={"Artificial Intelligence"}
          />
        </Grid>
      </Grid>

      <Dialog onClose={handleClose} open={open} className={classes.refoDialog} fullWidth
      maxWidth="100%">
      <DialogTitle
          className={classes.dialogTitlePosition}
        >
          <Typography className={classes.dialogTitle}>Work flow </Typography>
          <CloseIcon
            size='14px'
            className={classes.closeBtn}
            onClick={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          <img src={RefoWorkFlow} alt="refo" width="100%"/>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Header;
