import { Grid, Box } from "@mui/material";
import React from "react";
import refoDashboard from "../../assets/refoDashboard.jpg";
import { Typography } from "@material-ui/core";
import useStyles from "../../theme/style.js";
import abstract from "../../assets/designs/abstract.png";
import Image1 from "../../assets/whyRefo/image1.png"
import Image2 from "../../assets/whyRefo/image2.png"
import { Parallax } from "react-parallax";
import ColorSlidesTwo from "../../components/ColorSlidesTwo";

function WhyRefo() {
  const classes = useStyles();



  return (
    <div>
      {/* content-1 */}
      <Parallax strength={500}>
        <Grid container direction='row' alignItems='center' >
          <Grid
            item
            xs={12}
            md={6}
            p={5}
            className={classes.whyRefoContainer}
          >
            <Typography
              variant='h1'
              className={classes.cmnTitle}
            >
              Move beyond ATS.
            </Typography>
            <Typography variant='h6' className={classes.h6}>
              Leave limitations of generic hiring with <span className="refo-font">refo</span> cloud as it assures feedback to your submitted candidate cvs' from Clients. <span className="refo-font">Refo</span> will soon embed AI into the core of hiring model, giving you the power to see outcomes quickly.
            </Typography>
            {/* <Button
              variant='contained'
              size='medium'
              sx={{
                borderRadius: "50px",
                width: "max-content",
                fontSize: "12px",
              }}
              className={classes.primaryBtn}
            >
              Learn more
            </Button> */}
          </Grid>
          <Grid item xs={12} md={6}  className={classes.flexCenter}>
            <img src={refoDashboard} alt='img' className={classes.refoDashboardImg} />
          </Grid>
        </Grid>
      </Parallax>

      {/* content-2 */}
      <ColorSlidesTwo shapeImg={abstract}  bgcolor='#243763' />

      {/* content-3 */}
      <Grid container direction='row' alignItems='center' >
        <Grid
          item
          xs={12}
          md={6}
          p={5}
          className={classes.whyRefoContainer}
        >
          <Box
            sx={{ typography: "body", fontWeight: "bold", fontSize: "13px",color:"#243763" }}
            className={classes.dividerColor}
          >
            BEST-IN-CLASS APPLICATIONS
          </Box>
          <Typography
            variant='h1'
            className={classes.cmnTitle}
          >
            Lead in the future of work.
          </Typography>
          <Typography variant='h6' className={classes.h6}>
          <span className="refo-font">refo</span>  is the future of collective, collaborative, co-operative and creative hiring
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} pb={5} className={classes.flexCenter}>
          <img src={Image1} alt='img' className={classes.imgResponsive}/>
        </Grid>
      </Grid>

      {/* content-4 */}
      <Grid container direction='row' alignItems='center' >
        <Grid item xs={12} md={6} pb={5} order={{ md: 1, xs: 2 }} className={classes.flexCenter}>
          <img src={Image2} alt='img' className={classes.imgResponsive} />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          p={5}
          order={{ md: 2, xs: 1 }}
          className={classes.whyRefoContainer}
        >
          <Box
            sx={{ typography: "body", fontWeight: "bold", fontSize: "13px",color:"#243763" }}
            className={classes.dividerColor}
          >
            ENTERPRISE MANAGEMENT CLOUD PLATFORM
          </Box>
          <Typography
            variant='h1'
            className={classes.cmnTitle}
          >
            Gain the power to adapt.
          </Typography>
          <Typography variant='h6' className={classes.h6}>
          <span className="refo-font">refo</span>  is an enabler giving the power to adapt before you adopt technology at full scale
          </Typography>
        </Grid>
      </Grid>

    </div>
  );
}

export default WhyRefo;
