import React from "react";

const SimpleBox = ({ title, content, link, selectedColor }) => {

  return (
    <div className='ag-format-container'>
      <div className='ag-courses_box'>
        <div className='ag-courses_item'>
          <div className='ag-courses-item_link'>
            <div className='ag-courses-item_bg' style={{ backgroundColor: selectedColor }}></div>

            <div className='ag-courses-item_title'>
              {title}
            </div>

            <div className='ag-courses-item_date-box'>
              {content}
            </div>
            <span className='ag-courses-item_date'>{link}</span>
          </div>
        </div>
      </div>
    </div>


  );
};

export default SimpleBox;
