import { useState } from "react";
import React from "react";
import {
  Drawer,
  IconButton,Box
} from "@mui/material";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-scroll";
import useStyles from "../theme/style"
import icon1 from "../assets/Icons/home.png"
import icon2 from "../assets/Icons/office-building.png"
import icon4 from "../assets/Icons/rating.png"

const DrawerComp = ({ links }) => {
  const [open, setOpen] = useState(false);
  const classes=useStyles()
  
  return (
    <>
      <Drawer
        PaperProps={{ sx: { backgroundColor: "#243763" } }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <nav>
          <ul className="unOrder">
            <li className="list-item">
              <img src={icon1} alt="Home"/>
              <Link
                to='home'
                spy={true}
                smooth={true}
                offset={-70}
                duration={2000}
              >
                Home
              </Link>
            </li>
            <li className="list-item">
              <img src={icon2} alt="Why refo"/>
              <Link
                to='why_refo'
                spy={true}
                smooth={true}
                offset={-70}
                duration={2000}
              >
                Why <span className="refo-font">refo</span> 
              </Link>
            </li>
            
            <li className="list-item">
              <img src={icon4} alt="customer experience"/>
              <Link
                to='customer_experience'
                spy={true}
                smooth={true}
                offset={-70}
                duration={2000}
              >
                Customer Experience
              </Link>
            </li>
            
            <li className="list-item" >
            <Box className="registration_firm">
                <a
                href={`${process.env.REACT_APP_DOMAIN_URL}v1/#/register`}
                className="iconBlink"
                >        
                   Recruitment Firm Registration
                </a>
                </Box>
            </li>
            <li className="list-item">
              <Box>
                <a  href={`${process.env.REACT_APP_DOMAIN_URL}v1/#/login`} className={classes.btnLogin}> Login </a>
              </Box>
            </li>
          </ul>
        </nav>
        {/* <img src={peek} className={classes.sideBarPeek} alt="peeking"/> */}
      </Drawer>
      <p style={{position:"absolute",right:"5%",top:"-15%"}}> </p>
      <IconButton
        style={{color:"#fff",position:"absolute",right:"5%"}}
        onClick={() => setOpen(!open)}
      >
        <MenuIcon />
      </IconButton>
    </>
  );
};

export default DrawerComp;
