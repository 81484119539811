import React from "react";
import Header from "../components/Header";
import DetailList from "../components/DetailList";
import WhyRefo from "./WhyRefo/WhyRefo";
import Resources from "./Resources/Resources";

function Home() {
  return (
    <div className='home_container'>
      <div id='home'>
        <Header />
      </div>
      <div id='why_refo'>
        <WhyRefo />
      </div>
      <div id='customer_experience'>
        <Resources />
        <DetailList />
      </div>
    </div>
  );
}

export default Home;
