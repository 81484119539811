import { Grid } from '@mui/material'
import React from 'react'
import { Typography } from "@material-ui/core";
import useStyles from "../theme/style.js";
const ColorSlidesTwo = ({contentText,bgcolor,shapeImg}) => {
  const classes = useStyles();

  return (
    <div>
        <Grid container p={5} bgcolor={bgcolor}>
            <Grid item xs={12} md={6}  order={{md:1,xs:2}}>
            <div className={classes.colorSlides}>
                <Typography variant='h2'>
                    The business landscape is fast changing.
                </Typography>
                <Typography variant='h2'>
                    And, revenue models are evolving with AI onslaught.
                </Typography>
                <Typography variant='h2'>
                    To scale at speed, technology adoption with the right tool becomes paramount.
                </Typography>
                <Typography variant='h2'>
                    Can save appreciable cost.
                </Typography>
            </div>
                
            </Grid>
            <Grid item xs={12} md={6} p={5} className={classes.flexCenter} order={{md:2,xs:1}}>
                <img src={shapeImg} alt='images' width="40%" className={classes.imgShapes}></img>
            </Grid>
            
        </Grid>
    </div>
  )
}

export default ColorSlidesTwo